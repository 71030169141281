<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title class="tc cWhite"
          ><div>{{ eventExpress }}終了</div></ion-title
        >
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="common-body-720-90 pb40">
        <div class="setting-inquiry-top-text-title">
          {{ eventExpress }}お疲れさまでした！
        </div>
        <div class="setting-inquiry-body tc cWhite">
          <!-- <div class="setting-inquiry-text-body"></div> -->
          <div class="setting-inquiry-form-body tl">
            <div v-show="step === 1">
              <div class="setting-inquiry-form-score-body">
                <div>音声と動画の品質はいかがでしょうか？</div>
                <div class="setting-inquiry-form-star-body clearfix">
                  <div class="setting-inquiry-form-star-item">
                    <ion-icon
                      :icon="score > 0 ? star : starOutline"
                      class="cWhite"
                      @click="addScore(1)"
                    ></ion-icon>
                  </div>
                  <div class="setting-inquiry-form-star-item">
                    <ion-icon
                      :icon="score > 1 ? star : starOutline"
                      class="cWhite"
                      @click="addScore(2)"
                    ></ion-icon>
                  </div>
                  <div class="setting-inquiry-form-star-item">
                    <ion-icon
                      :icon="score > 2 ? star : starOutline"
                      class="cWhite"
                      @click="addScore(3)"
                    ></ion-icon>
                  </div>
                  <div class="setting-inquiry-form-star-item">
                    <ion-icon
                      :icon="score > 3 ? star : starOutline"
                      class="cWhite"
                      @click="addScore(4)"
                    ></ion-icon>
                  </div>
                  <div class="setting-inquiry-form-star-item">
                    <ion-icon
                      :icon="score > 4 ? star : starOutline"
                      class="cWhite"
                      @click="addScore(5)"
                    ></ion-icon>
                  </div>
                </div>
                <div class="clearfix">
                  <div class="fl">とても悪い</div>
                  <div class="fr">とても良い</div>
                </div>
              </div>
            </div>
            <div v-show="step === 2" class="setting-inquiry-form-step2-body">
              <div
                class="setting-inquiry-form-item"
                v-for="(item, index) in questions"
                :key="index"
              >
                <div class="setting-inquiry-form-label">{{ item.title }}</div>
                <div v-if="item.type === 1">
                  <input
                    type="text"
                    class="setting-inquiry-form-input"
                    v-model="item.value"
                    @input="inputChange(index)"
                  />
                </div>
                <div v-if="item.type === 3">
                  <div
                    v-for="(checkbox, checkboxIndex) in item.optionArr"
                    :key="checkboxIndex"
                    class="setting-inquiry-form-option-item"
                  >
                    <label
                      ><input
                        type="checkbox"
                        :value="checkbox.value"
                        v-model="item.value"
                        @change="inputChange(index)"
                      />
                      {{ checkbox.name }}</label
                    >
                    <div>
                      <input
                        class="setting-inquiry-form-input"
                        type="text"
                        v-if="
                          checkbox.name === 'その他' &&
                            returnIndexOf(item.value, checkbox.value) > -1
                        "
                        v-model="item.otherValue"
                        @change="inputChange(index)"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="item.type === 2">
                  <div
                    v-for="(radio, radioIndex) in item.optionArr"
                    :key="radioIndex"
                    class="setting-inquiry-form-option-item"
                  >
                    <label
                      ><input
                        type="radio"
                        :value="radio.value"
                        v-model="item.value"
                        @change="inputChange(index)"
                      />
                      {{ radio.name }}</label
                    >
                    <div>
                      <input
                        class="setting-inquiry-form-input"
                        type="text"
                        v-if="
                          radio.name === 'その他' && item.value === radio.value
                        "
                        v-model="item.otherValue"
                        @change="inputChange(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="step === 3">
              <div
                class="setting-inquiry-form-item"
                v-for="(item, index) in form"
                :key="index"
              >
                <div class="setting-inquiry-form-label">{{ item.title }}</div>
                <div class="setting-inquiry-form-callbacl-value">
                  {{ item.answer || 'なし' }}
                </div>
              </div>
            </div>

            <div v-show="step === 1">
              <!-- <ion-button
                expand="block"
                class="setting-inquiry-send-btn"
                @click="nextStep"
                >次へ</ion-button
              > -->
              <div class="mb15">
                <ion-button
                  expand="block"
                  class="setting-inquiry-send-btn"
                  @click="send"
                  >参加証発行へ</ion-button
                >
              </div>
              <!-- <div>
                <ion-button
                  expand="block"
                  class="setting-inquiry-send-btn"
                  @click="nextStep"
                  >視聴不具合記入</ion-button
                >
              </div> -->
              <div class="f12 tc cWhite">
                <div class="dib pointer mt5">
                  <a
                    href="javascript:void(0)"
                    @click="nextStep"
                    class="cWhite noUnderline"
                    >視聴に関する不具合はこちら＞＞</a
                  >
                </div>
              </div>
            </div>
            <div v-show="step === 2">
              <ion-button
                expand="block"
                class="setting-inquiry-send-btn"
                @click="send"
                >次へ</ion-button
              >
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonIcon
} from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  chevronBackOutline,
  bookmarkSharp,
  chevronForwardOutline,
  logOutOutline,
  starOutline,
  star
} from 'ionicons/icons'
import { authApi } from '../../../api'

export default defineComponent({
  name: 'SettingInquiry',
  data() {
    return {
      step: 1,
      score: 0,
      chevronBackOutline,
      bookmarkSharp,
      chevronForwardOutline,
      logOutOutline,
      starOutline,
      star,
      questions: [],
      form: [],
      id: this.$route.params.id
    }
  },
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon
  },
  ionViewDidEnter() {
    if (this.id) {
      this.getInquiry()
    }
  },
  computed: {
    eventExpress() {
      return this.$store.getters.eventExpress
    }
  },
  methods: {
    addScore(score) {
      this.score = score
    },
    returnIndexOf(arr, key) {
      return arr.indexOf(key)
    },
    inputChange(i) {
      const value = this.questions[i].value
      const type = this.questions[i].type
      const optionValues = this.questions[i].optionValues
      const mutiValue = []
      let valueStr = ''
      switch (type) {
        case 1:
          valueStr = value
          break

        case 2:
          valueStr = optionValues[value]
          if (valueStr === 'その他') {
            valueStr = `${valueStr}：${this.questions[i].otherValue}`
          }
          break
        case 3:
          value.forEach(item => {
            if (optionValues[item] === 'その他') {
              mutiValue.push(
                `${optionValues[item]}：${this.questions[i].otherValue}`
              )
            } else {
              mutiValue.push(optionValues[item])
            }
          })
          valueStr = String(mutiValue)
          break
        default:
          break
      }
      this.form[i].answer = valueStr
      if (type === 3) {
        this.form[i].answerId = String(value)
      } else {
        this.form[i].answerId = value
      }
    },
    getInquiry() {
      authApi.getEnquete(this.id).then(res => {
        console.log(res)
        let q = res.data.questions
        q.forEach((item, i) => {
          if (item.type === 3) {
            q[i].value = []
          } else {
            q[i].value = ''
          }
          q[i].otherValue = ''
          this.form.push({
            questionId: item.questionId,
            title: item.title,
            answer: '',
            answerId: ''
          })
          const options = q[i].optionValues
          q[i].optionArr = []
          Object.keys(options).forEach(key => {
            const str = q[i].optionValues[key]
            q[i].optionArr.push({
              name: str,
              value: key
            })
          })
        })
        this.questions = q
        console.log(q)
      })
    },
    nextStep() {
      this.step = 2
    },
    preStep() {
      this.step = 1
    },
    send() {
      const formData = []
      if (this.score !== 0) {
        formData.push({
          questionId: '0',
          title: '音声と動画の品質はいかがでしょうか？',
          answer: String(this.score),
          answerId: '0'
        })
      }
      this.form.forEach(item => {
        if (item.answer !== '') {
          formData.push(item)
        }
      })
      if (formData.length > 0) {
        const params = {
          answerList: formData
        }
        authApi
          .saveEnquete(this.id, params)
          .then(res => {
            console.log(res)
            this.$router.replace({ name: 'StreamingEnd' })
          })
          .catch(() => {
            this.$toast.error('送信失敗しました')
          })
      } else {
        this.$router.replace({ name: 'StreamingEnd' })
      }
    }
  }
})
</script>

<style scoped>
.setting-inquiry-text-body {
  padding-top: 20px;
}
.setting-inquiry-form-body {
  padding-top: 0px;
}
.setting-inquiry-form-label {
  font-size: 14px;
  font-weight: bold;
}
.setting-inquiry-form-input {
  height: 46px;
  background-color: #8a8a8a;
  border-radius: 4px;
  opacity: 0.5;
  width: 100%;
  border: 0px;
  padding: 5px 10px;
  margin-top: 5px;
}
.setting-inquiry-form-step2-body {
  margin: 20px 0;
}
.setting-inquiry-form-item {
  margin-bottom: 15px;
  /* border-bottom: 1px solid #fff; */
  padding-bottom: 15px;
  max-width: 300px;
  margin: 0 auto;
}
.setting-inquiry-form-input.type-textarea {
  height: 211px;
}
.setting-inquiry-send-btn {
  width: 300px;
  margin: 0 auto;
  font-size: 12px;
  height: 41px;
  line-height: 41px;
}
.setting-inquiry-form-option-item {
  margin: 5px 0;
}
.setting-inquiry-form-callbacl-value {
  background-color: #8a8a8a;
  border-radius: 4px;
  opacity: 0.5;
  min-height: 31px;
  padding: 5px 10px;
  margin-top: 5px;
}
.setting-inquiry-send-btn-div {
  width: 40%;
  margin: 0 3%;
}
.setting-inquiry-send-btn-div .setting-inquiry-send-btn {
  width: 100%;
}
.setting-inquiry-form-score-body {
  max-width: 300px;
  margin: 20px auto 40px auto;
  background: rgba(224, 81, 147, 0.27);
  padding: 20px;
  border-radius: 10px;
}
.setting-inquiry-form-star-body {
  margin-top: 15px;
}
.setting-inquiry-form-star-item {
  width: 20%;
  float: left;
  text-align: center;
  font-size: 32px;
}
.setting-inquiry-form-star-item ion-icon {
  color: #e05193;
}
.setting-inquiry-top-text-title {
  font-size: 16px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
}
ion-item {
  --padding-start: 0px;
  --border-color: rgba(0, 0, 0, 0);
}
ion-checkbox {
  --border-color: rgb(153 153 153);
}
</style>
